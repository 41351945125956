import { createCipheriv, createDecipheriv, randomBytes } from 'crypto';

export default class Crypto {
  private static algorithm = 'aes-256-cbc'; // AES encryption algorithm
  private static key = Buffer.from(`${process.env.REQUEST_SECRET_KEY}`, 'hex'); // 32 bytes key for AES-256
  private static ivLength = 16; // AES block size for CBC mode

  public static encrypt(data: string, encodeOutputForURL:boolean=true): string {
    // Generate a random 16-byte IV
    const iv = randomBytes(this.ivLength);
    const cipher = createCipheriv(this.algorithm, this.key, iv);

    let encrypted = cipher.update(data, 'utf-8', 'base64');
    encrypted += cipher.final('base64');

    // Combine IV and encrypted data into a single string, separated by a colon
    const encryptedData = iv.toString('base64') + ':' + encrypted;

    return encodeOutputForURL? encodeURIComponent(encryptedData) : encryptedData;
  }

  public static decrypt(data: string, dataURLEncoded:boolean=true): string {
    // Split the combined IV and encrypted data
    data = dataURLEncoded? decodeURIComponent(data) : data;

    const parts = data.split(':');
    if (parts.length !== 2) {
      throw new Error('Invalid encrypted data format');
    }

    const ivBase64 = parts[0];
    const encryptedText = parts[1];

    // Decode the IV from Base64
    const iv = Buffer.from(ivBase64, 'base64');

    // Ensure IV length is correct before decrypting
    if (iv.length !== this.ivLength) {
      throw new TypeError('Invalid initialization vector length');
    }

    const decipher = createDecipheriv(this.algorithm, this.key, iv);

    let decrypted = decipher.update(encryptedText, 'base64', 'utf-8');
    decrypted += decipher.final('utf-8');

    return decrypted;
  }

  public static encode(data: string) {
    return Buffer.from(data).toString('base64');
  }

  public static decode(data: string) {
    return Buffer.from(data, 'base64').toString('ascii');
  }
}
