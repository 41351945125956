import { createContext, useContext, useState, useMemo } from "react";
/**
 * SupportContextProps is the shape of the context value
 * @property {boolean} isOpen - Whether the support modal is open
 * @property {Function} openSupport - Function to open the support modal
 * @property {Function} closeSupport - Function to close the support modal
 * @typedef {object} SupportContextProps
 */
interface SupportContextProps {
    isOpen: boolean;
    openSupport: () => void;
    closeSupport: () => void;
}

const SupportContext = createContext<SupportContextProps|undefined>(undefined);
/**
 * SupportProvider component that provides the support form context to its children.
 * @param {React.ReactNode} children
 * @returns {JSX.Element} The provider component
 */
export const SupportProvider: React.FC <{children: React.ReactNode}> = ({children}) => {
    const [isOpen, setIsOpen] = useState(false);

    const openSupport = () => setIsOpen(true);
    const closeSupport = () => setIsOpen(false);

    // Memoize the context value to avoid unnecessary re-renders
    const contextValue = useMemo(
        () => ({ isOpen, openSupport, closeSupport }), 
        [isOpen]
    );

    return (
        <SupportContext.Provider value={contextValue}>
            {children}
        </SupportContext.Provider>
    );
};
/**
 * Custom hook to access the support context
 * @throws {Error} - If the hook is used outside of a SupportProvider
 * @returns {SupportContextProps} The support context value
 */
export const useSupport = () => {
    const context = useContext(SupportContext);
    if (!context) {
        throw new Error('useSupport must be used within a SupportProvider');
    }
    return context;
};