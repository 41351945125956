import { CreateTicketDto } from "@/app/types/support";
import axiosHelper from "@/app/utils/axiosHelper";
import { AxiosResponse } from "axios";

class SupportService{
    private readonly baseUrl: string;
    constructor() {
        this.baseUrl = `${process.env.BACKEND_URL}/backend/support`;
    }
    /**
     * Submit a ticket
     * @param {CreateTicketDto} ticketData 
     * @returns {Promise<AxiosResponse<any>>}
     */
    async submitTicket(ticketData: CreateTicketDto): Promise<AxiosResponse<any>> {
        return await axiosHelper.post(`api/proxy?url=${this.baseUrl}`, ticketData);
    }
}

export const supportService = new SupportService();