export function HubspotTracker() {
  return (
    <script
      type="text/javascript"
      id="hs-script-loader"
      async
      defer
      src="//js-eu1.hs-scripts.com/145144025.js"
    ></script>
  );
}
