import { createCipheriv, randomFillSync } from 'crypto';
import replaceBackendUrl from './replaceBackendUrl';

class Logger {
  private static user: string;

  // Initialize to get user data
  public static initializeUser(userEmail: string) {
    if (userEmail) Logger.user = userEmail;
  }

  // Encrypt data using a hex-encoded secret key and AES-256-CBC
  private static async encryptData(
    hexKey: string,
    data: string,
  ): Promise<{ encryptedData: string; iv: string }> {
    // Convert the provided hex string key to a Uint8Array
    const key = Logger.hexStringToUint8Array(hexKey);

    // Generate an initialization vector (IV) of 16 bytes
    const iv = new Uint8Array(16);
    randomFillSync(iv);

    // Create a cipher instance with the key and IV
    let encrypted: Buffer;
    try {
      const cipher = createCipheriv('aes-256-cbc', Buffer.from(key), iv);
      encrypted = Buffer.concat([
        cipher.update(Buffer.from(data)),
        cipher.final(),
      ]);
    } catch (error) {
      Logger.error(`Error during encryption. ${JSON.stringify(error)}`);
      throw error;
    }

    return {
      encryptedData: Logger.bufferToBase64(encrypted),
      iv: Logger.bufferToBase64(iv),
    };
  }

  // Utility method to convert a hex string to a Uint8Array
  private static hexStringToUint8Array(hexString: string): Uint8Array {
    if (hexString.length % 2 !== 0) {
      throw new Error('Invalid hex string length.');
    }

    const arrayBuffer = new Uint8Array(hexString.length / 2);
    for (let i = 0; i < hexString.length; i += 2) {
      const byteValue = parseInt(hexString.substring(i, i + 2), 16);
      arrayBuffer[i / 2] = byteValue;
    }

    return arrayBuffer;
  }

  // Utility method to convert an ArrayBuffer or Uint8Array to Base64
  private static bufferToBase64(buf: ArrayBuffer | Uint8Array): string {
    return Buffer.from(buf).toString('base64');
  }
  /**
   * Log error messages
   * @param {string} message The log message
   * @param {'info' | 'error'} type Type of message
   * @param { 'api' | 'client' } context The context of log. Important to add api for api level logs
   */
  public static async log(
    message: string,
    type: 'info' | 'error' = 'info',
    context: 'api' | 'client' = 'client',
  ) {
    const logMessage = {
      message: this.addUserInfoToMessage(message),
      type: type,
      project: 'frontend',
    };

    const logSecretKey = `${process.env.LOG_SECRET_KEY}`;
    const keyBuffer = Logger.hexStringToUint8Array(logSecretKey);

    try {
      const { encryptedData, iv } = await Logger.encryptData(
        logSecretKey,
        JSON.stringify(logMessage),
      );
      const logEndPoint = `/backend/log`;
      //We CANT use axiosHelper here because it uses Logger to log errors, so it will be in infinite loop
      const response = await fetch(
        context == 'api' || process.env.NODE_ENV === 'development' ? replaceBackendUrl(logEndPoint): logEndPoint,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${process.env.BACKEND_API_KEY}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ log: encryptedData, iv }),
        },
      );

      if (!response.ok) {
        throw new Error(`Failed to log message. Status: ${response.status}`);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error logging message:', error);
    }
  }
  /**
   * Log error messages
   * @param {string} message The log message
   * @param { 'api' | 'client' } context The context of log. Important to add api for api level logs
   * @param context
   * @returns
   */
  public static async error(
    message: string,
    context: 'api' | 'client' = 'client',
  ) {
    return Logger.log(message, 'error', context);
  }
  /**
   * Log info messages
   * @param {string} message The log message
   * @param { 'api' | 'client' } context The context of log. Important to add api for api level logs
   * @param context
   * @returns
   */
  public static async info(
    message: string,
    context: 'api' | 'client' = 'client',
  ) {
    return Logger.log(message, 'info', context);
  }

  private static addUserInfoToMessage(message: string): string {
    const userEmail = Logger.user ? Logger.user : '';
    return `userEmail: ${userEmail}; message: ${message}`;
  }
}

export default Logger;
