const proxy_url: string =
  process.env.BACKEND_PROXY_URL ?? 'http://localhost:3000';
const backend_url: string = process.env.BACKEND_URL ?? 'http://localhost:3000';
const isBrowser = typeof window !== 'undefined';
/**
 * Replace the backend url with proxy accordingly
 * @author Khaleel <k.karim@fugro.com>
 * @param {string} url
 * @returns {string}
 */
const replaceBackendUrl = (url: string) => {
  return (
    (isBrowser ? backend_url : proxy_url) +
    url.substring(url.indexOf('/backend'))
  );
};

export default replaceBackendUrl;
