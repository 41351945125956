import React, {useEffect} from 'react';
import { useRouter } from 'next/router';

const PaymentRedirect: React.FC = () => {
  const router = useRouter();
  const handleRedirect = () => {
    router.push('/payment/redirect');
  };

  useEffect(() => {
    const priceId = sessionStorage.getItem('priceId');
    const planId = sessionStorage.getItem('planId');
    if (priceId && planId) {
      handleRedirect();
    }
  }, []);

  return null;
};

export default PaymentRedirect;
